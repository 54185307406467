import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { a } from 'gatsby-plugin-google-analytics';
import Global from './global';
import Header from './header';
import padding from '../util/padding';

const svgIconUrl = require('../images/icon.svg') as string;

const Layout = ({
  withHero,
  children,
}: React.PropsWithChildren<{ withHero?: boolean }>) => {
  const data = useStaticQuery<{
    site: {
      siteMetadata: { title: string };
    };
    currentBuildDate: { currentDate: string };
  }>(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      currentBuildDate {
        currentDate
      }
    }
  `);

  return (
    <>
      <Global />
      <Helmet>
        <link rel="icon" href={svgIconUrl} />
      </Helmet>
      <Header
        siteTitle={data.site.siteMetadata.title}
        css={padding}
        withHero={withHero}
      />
      <main
        css={css`
          padding-top: ${withHero ? null : '150px'};
        `}
      >
        {children}
      </main>
      <footer
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-family: sans-serif;
          font-weight: lighter;
          font-size: 14px;
          margin-top: 150px;
          min-height: 20vh;
        `}
      >
        <p>
          © {data.currentBuildDate.currentDate} {data.site.siteMetadata.title}
        </p>
        <p
          css={css`
            margin-bottom: 0.5em;
          `}
        >
          Follow us on:
        </p>
        <p>
          <a
            href="https://fb.me/lovesnotsilent"
            target="_blank"
            title="Facebook"
          >
            <FontAwesomeIcon
              icon={faFacebookF}
              color="#3B5998"
              size="2x"
              style={{ marginRight: '1em' }}
            />
          </a>
          <a
            href="https://twitter.com/lovesnotsilent"
            target="_blank"
            title="Twitter"
          >
            <FontAwesomeIcon
              icon={faTwitter}
              color="#1DA1F2"
              size="2x"
              style={{ marginRight: '1em' }}
            />
          </a>
          <a
            href="https://www.instagram.com/_loveisnotsilent/"
            target="_blank"
            title="Instagram"
          >
            <FontAwesomeIcon icon={faInstagram} color="#C13584" size="2x" />
          </a>
        </p>
      </footer>
    </>
  );
};

export default Layout;
