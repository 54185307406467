import * as React from 'react';

export const useScrolled = (triggerOffset: number) => {
  const [scrolled, setScrolled] = React.useState(false);

  React.useEffect(() => {
    const handler = () => {
      if (window.pageYOffset >= triggerOffset) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, [setScrolled, triggerOffset]);

  return scrolled;
};
