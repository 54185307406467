import * as React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Logo from './logo';
import { transitionTime, easeOutExpo } from '../util/easings';
import { useScrolled } from '../util/hooks';
import { breaks } from '../util/mq';

const initialHeight = 105;
const postScrollHeight = 65;
const scrollTrigger = (105 - 45) / 2 + 45;

export type Props = {
  siteTitle: string;
  withHero?: boolean;
} & Pick<React.HTMLProps<HTMLDivElement>, 'className'>;

const StyledLink = styled(Link, { shouldForwardProp: isPropValid })<{
  white?: boolean;
  scrolled?: boolean;
  withHero?: boolean;
  navOpen?: boolean;
}>`
  color: ${({ scrolled, withHero, navOpen }) =>
    (scrolled || !withHero) && !navOpen ? '#000' : 'rgba(255, 255, 255, 0.75)'};
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  transition: color ${transitionTime} ${easeOutExpo};

  &.active,
  &:hover {
    color: ${({ white, navOpen }) => (white || navOpen ? '#FFF' : '#A70D00')};
  }
`;

const NavLi = styled('li')`
  display: inline;
  margin-left: 16px;
  @media (max-width: ${breaks.medium}) {
    display: block;
  }
`;

const Header = ({ siteTitle, withHero, className }: Props) => {
  const innerHeight = typeof window !== 'undefined' ? window.innerHeight : 0;

  const scrolled = useScrolled(
    withHero ? innerHeight - scrollTrigger : scrollTrigger,
  );

  const white = withHero && !scrolled;

  const [navOpen, setNavOpen] = React.useState(false);

  const openNav = React.useCallback(() => {
    setNavOpen(true);
  }, [setNavOpen]);

  const closeNav = React.useCallback(() => {
    setNavOpen(false);
  }, [setNavOpen]);

  return (
    <header
      className={className}
      css={css`
        width: 100vw;
        height: ${scrolled ? `${postScrollHeight}px` : `${initialHeight}px`};
        background: ${scrolled ? '#FFF' : null};
        box-shadow: ${scrolled ? '0 2px 2px rgba(0, 0, 0, 0.1)' : null};
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 3;
        transition: all ${transitionTime} ${easeOutExpo};
        @media print {
          display: none;
        }
      `}
    >
      <Link to="/" title={siteTitle}>
        <Logo white={white} />
      </Link>
      <button
        onClick={openNav}
        css={css`
          background: none;
          border: none;
          margin-left: auto;
          cursor: pointer;
          display: none;
          @media (max-width: ${breaks.medium}) {
            display: block;
          }
        `}
      >
        <FontAwesomeIcon icon={faBars} color={white ? '#FFF' : '#000'} />
      </button>
      <nav
        css={css`
          margin-left: auto;
          @media (max-width: ${breaks.medium}) {
            width: 100vw;
            height: 100vh;
            position: fixed;
            padding: 1em;
            top: 0;
            background: #333;
            left: 100vw;
            transform: ${navOpen ? 'translate3d(-100vw, 0, 0)' : 'none'};
            transition: transform ${transitionTime} ${easeOutExpo};
          }
        `}
      >
        {navOpen ? (
          <button
            onClick={closeNav}
            css={css`
              background: none;
              border: none;
              position: absolute;
              top: 1em;
              right: 1em;
              cursor: pointer;
            `}
          >
            <FontAwesomeIcon icon={faTimes} color="#FFF" />
          </button>
        ) : null}
        <ul
          css={css`
            margin: 0;
          `}
        >
          {[
            { to: '/', text: 'Home' },
            { to: '/shareables', text: 'Shareables' },
            { to: '/resources', text: 'Resources' },
            { to: '/about', text: 'About' },
            { to: '/contact', text: 'Contact' },
          ].map(({ text, ...props }) => (
            <NavLi key={props.to}>
              <StyledLink
                {...props}
                {...{ white, scrolled, withHero, navOpen }}
                activeClassName="active"
                onClick={closeNav}
              >
                {text}
              </StyledLink>
            </NavLi>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
