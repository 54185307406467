import * as React from 'react';
import { Global, css } from '@emotion/core';
import 'sanitize.css';
import Typography from 'typography';
import 'typeface-catamaran';
import 'typeface-raleway';

const typography = new Typography({
  baseFontSize: '19px',
  baseLineHeight: 1.68,
  scaleRatio: 2,
  headerFontFamily: ['Catamaran'],
  bodyFontFamily: ['Raleway'],
});

export default () => (
  <>
    <Global styles={typography.toString()} />
    <Global
      styles={css`
        body {
          background: #fafafa;
          display: flex;
          justify-content: center;
        }

        a {
          color: #a70d00;
        }

        .rtBibleRef {
          color: inherit;
          text-decoration: none;
          border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
        }

        .rtTooltip {
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
        }
      `}
    />
  </>
);

// @ts-ignore
global.refTagger = {
  settings: {
    bibleVersion: 'ESV',
    dropShadow: false,
    roundCorners: true,
    socialSharing: ['twitter', 'facebook'],
    tagChapters: true,
    customStyle: {
      heading: {
        backgroundColor: '#ffffff',
        color: '#a70d00',
        fontFamily: 'Tahoma, Verdana, Segoe, sans-serif',
        fontSize: '14px',
      },
      body: {
        fontFamily: 'Tahoma, Verdana, Segoe, sans-serif',
        fontSize: '14px',
      },
    },
  },
};

// @ts-ignore
if (global.document) {
  // @ts-ignore
  const taggerScript = global.document.createElement('script');
  taggerScript.type = 'text/javascript';
  taggerScript.async = true;
  taggerScript.src = 'https://api.reftagger.com/v2/RefTagger.js';
  // @ts-ignore
  global.document.getElementsByTagName('body')[0].appendChild(taggerScript);
}
