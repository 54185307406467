import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { css } from '@emotion/core';

export type Props = {
  height?: number;
  white?: boolean;
};

const Logo = ({ height = 45, white }: Props) => {
  const data = useStaticQuery(graphql`
    {
      logo1x: file(relativePath: { eq: "logo.png" }) {
        publicURL
      }
      logo2x: file(relativePath: { eq: "logo@2x.png" }) {
        publicURL
      }
      logo3x: file(relativePath: { eq: "logo@3x.png" }) {
        publicURL
      }
      logoWhite1x: file(relativePath: { eq: "logo-white.png" }) {
        publicURL
      }
      logoWhite2x: file(relativePath: { eq: "logo-white@2x.png" }) {
        publicURL
      }
      logoWhite3x: file(relativePath: { eq: "logo-white@3x.png" }) {
        publicURL
      }
    }
  `);
  return (
    <img
      src={white ? data.logoWhite1x.publicURL : data.logo1x.publicURL}
      srcSet={
        white
          ? `${data.logoWhite1x.publicURL} 1x, ${data.logoWhite2x.publicURL} 2x, ${data.logoWhite3x.publicURL} 3x`
          : `${data.logo1x.publicURL} 1x, ${data.logo2x.publicURL} 2x, ${data.logo3x.publicURL} 3x`
      }
      css={css`
        height: ${height}px;
        margin: 0;
      `}
      alt="Love Is Not Silent"
    />
  );
};

export default Logo;
