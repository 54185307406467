import { css } from '@emotion/core';
import { breaks } from './mq';

export default css`
  width: 100vw;
  @media (max-width: ${breaks.small}) {
    padding: 0 1em;
  }
  @media (min-width: ${breaks.medium}) {
    padding-left: calc((100vw - ${breaks.medium}) / 2);
    padding-right: calc((100vw - ${breaks.medium}) / 2);
  }
  @media (min-width: ${breaks.large}) {
    padding-left: calc((100vw - ${breaks.large}) / 2);
    padding-right: calc((100vw - ${breaks.large}) / 2);
  }
  @media (min-width: ${breaks.extraLarge}) {
    padding-left: calc((100vw - ${breaks.extraLarge}) / 2);
    padding-right: calc((100vw - ${breaks.extraLarge}) / 2);
  }
`;
